@import '../../shared.scss';

@font-face {
    font-family: "HanSans Medium";
    src: local('HanSans-Medium'), url("../../fonts/SourceHanSansCN-Medium.woff");
}

.root {
    padding: 0;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    min-height: 100vh;
    background: black;
    font-family: 'HanSans Medium', Futura, serif;
    .banner {
        background-color: black;
        display: flex;
        width: 100vw;
        height: 44.27vw;
        background-image: url('../../images/pc/drumnet.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 100%;
        @media (max-width: 500px) {
            margin-top: calc(100vh - 242px - 82vw);
            height: 82vw;
            background-image: url('../../images/mb/drumnet.png');

        }
        .logo {
            top: calc(4.5vw + 20px);
            left: 50px;
            position: absolute;
            height: 90px;
            width: 90px;
            @media (max-width: 500px) {
                //text-align: center;
                //left: calc(50vw - 36px);
                //top: 10vh;
                display: none;
            }
            @media (max-width: 1920px) {
                top: calc(4.5vw + 28px);
                height: 72px;
                width: 72px;
            }
        }
        .title {
            width: 514px;
            height: 129px;
            left: 160px;
            top: 3.5vw;
            position: absolute;
            font-family: 'HanSans-Medium', Futura, serif;
            
            font-weight: 500;
            font-size: 85px;
            line-height: 128px;
            color: #E7E9EF;
            @media (max-width: 1920px) {
                font-size: 4.42vw;
                left: 130px;
            }
            @media (max-width: 500px) {
                width: 100vw;
                text-align: center;
                left: 0px;
                top: 10vh;
                font-size: 64px;
            }
        }
        .description1 {
            width: 440px;
            height: 53px;
            right: 110px;
            top: calc(44.27vw - 120px);
            position: absolute;

            font-family: 'HanSans Medium', Futura, serif;
            
            font-weight: 500;
            font-size: 22px;
            line-height: 45px;
    
            text-align: right;
    
            color: #E7E9EF;
            @media (max-width: 500px) {
                width: 100vw;
                text-align: center;
                left: 0px;
                top: 24vh;
                font-size: 18px;
                line-height: 19px;
            }
        }
        .description2 {
            width: 540px;
            height: 53px;
            right: 110px;
            top: calc(44.27vw - 80px);
            position: absolute;

            font-family: 'HanSans Medium', Futura, serif;
            
            font-weight: 500;
            font-size: 42px;
            line-height: 45px;
    
            text-align: right;
    
            color: #E7E9EF;
        }
    }
    .options {
        width: 100vw;
        height: 65px;
        border-top: 1.5px solid white;
        border-bottom: 1.5px solid white;
        display: flex;
        justify-content: space-between;
        @media (max-width: 500px) {
            flex-direction: column;
            height: auto;
        }
        .option {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            color: white;
            font-size: 22px;
            cursor: pointer;
            @media (max-width: 500px) {
                justify-content: left;
                height: 65px;
                padding-left: 35vw;
            }
            .optionText {
                margin-left: 20px;
                font-family: 'HanSans Medium', Futura, serif;
                
                font-weight: 500;
                font-size: 20px;
                line-height: 27px;
                text-align: center;
                color: #FFFFFF;
                @media (max-width: 500px) {
                    font-size: 17px;
                    width: 80px;
                }
            }
            &.purple:hover{
                background: #7070F9;
                @media (max-width: 500px) {
                    background: none;
                }
            }
            &.pink:hover {
                background: #B95C7D;
                @media (max-width: 500px) {
                    background: none;
                }
            }
            &.yellow:hover {
                background: #B7803D;
                @media (max-width: 500px) {
                    background: none;
                }
            }
            
        }
    }
    .content {
        background: black;
        padding-top: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: 500px) {
            padding-top: 192px;
        }
    }
 
    .feature1 {
        position: relative;
        background-color: black;
        display: flex;
        width: 100vw;
        height: 39.1vw;
        background-image: url('../../images/pc/drumnet1.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 100%;
        @media (max-width: 500px) {
            background-image: url('../../images/mb/drumnet1.png');
            height: 54.6vw;
        }
        .feature1Title{
            position: absolute;
            top: 2vw;
            left: 58.5vw;
            max-width: 750px;
            font-family: 'HanSans Medium', Futura, serif;
            
            font-weight: 500;
            font-size: 85px;
            line-height: 5.2vw;
            /* or 118% */
            color: #7070F9;
            @media (max-width: 1920px) {
                font-size: 4.42vw;
            }
            @media (max-width: 500px) {
                font-size: 48px;
                line-height: 55px;
                max-width: 200px;
                top: -108px;
                left: auto;
                right: 18px;
                text-align: right;
            }
        }
        .feature1Description {
            position: absolute;
            top: 11.35vw;
            left: 65.5vw;
            max-width: 542px;
            height: 246px;
            font-family: 'HanSans Medium', Futura, serif;
            
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            color: #E7E9EF;
            margin-right: 22px;
            text-align: justify;
            @media (max-width: 500px){
                padding: 0px 20px;
                margin-right: 0px;
                left: 0px;
                top: 234px;
                height: auto;
                font-size: 18px;
                line-height: 35px;
            }
        }
    }
    .feature4 {
        position: relative;
        background-color: black;
        width: 100vw;
        height: 73.54vw;
        background-image: url('../../images/pc/drumnet5.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 100%;
        @media (max-width: 500px){
            margin-top: 312px;
            height: 101.28vw;
            background-image: url('../../images/mb/drumnet5.png');
        }
        .feature4Title{
            position: absolute;
            top: 16vw;
            left: 3.5vw;
            max-width: 920px;
            font-family: 'HanSans Medium', Futura, serif;
            
            font-weight: 500;
            font-size: 85px;
            line-height: 5.2vw;
            /* or 118% */
            color: #7070F9;
            @media (max-width: 1920px) {
                font-size: 4.42vw;
            }
            @media (max-width: 500px) {
                font-size: 48px;
                line-height: 55px;
                max-width: 350px;
                top: 30px;
                left: 16px;
                text-align: left;
            }
        }
        .feature4Description {
            position: absolute;
            top: 27.35vw;
            left: 50vw;
            max-width: 582px;
            height: 246px;
            font-family: 'HanSans Medium', Futura, serif;
            
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            color: #E7E9EF;
            margin-right: 22px;
            @media (max-width: 500px){
                padding: 0px 20px;
                margin-right: 0px;
                left: 0px;
                top: 420px;
                height: auto;
                font-size: 18px;
                line-height: 35px;
            }
        }
    }
    .feature2 {
        position: relative;
        background-color: black;
        width: 100vw;
        height: 69.6vw;
        background-image: url('../../images/pc/drumnet2.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 100%;
        @media (max-width: 500px){
            margin-top: 330px;
            height: 109vw;
            background-image: url('../../images/mb/drumnet2.png');
        }
        .feature2Title{
            position: absolute;
            top: 16vw;
            left: 3.5vw;
            max-width: 853px;
            font-family: 'HanSans Medium', Futura, serif;
            
            font-weight: 500;
            font-size: 85px;
            line-height: 5.2vw;
            /* or 118% */
            color: #7070F9;
            @media (max-width: 1920px) {
                font-size: 4.42vw;
            }
            @media (max-width: 500px) {
                font-size: 48px;
                line-height: 55px;
                max-width: 300px;
                top: -89px;
                left: 16px;
                text-align: left;
            }
        }
        .feature2Description {
            position: absolute;
            top: 31vw;
            left: 3.8vw;
            max-width: 29.7vw;
            height: 246px;
            font-family: 'HanSans Medium', Futura, serif;
            
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            color: #E7E9EF;
            @media (max-width: 500px){
                padding: 0px 22px;
                left: 0px;
                top: 434px;
                max-width: 100vw;
                height: auto;
            }
        }
    }
    .feature3 {
        position: relative;
        background-color: black;
        width: 100vw;
        height: 67.7vw;
        background-image: url('../../images/pc/drumnet3.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 100%;
        @media (max-width: 500px){
            margin-top: 292px;
            height: 115.7vw;
            background-image: url('../../images/mb/drumnet3.png');
        }
        .feature3Title{
            position: absolute;
            top: 7.5vw;
            left: 56vw;
            max-width: 750px;
            font-family: 'HanSans Medium', Futura, serif;
            
            font-weight: 500;
            font-size: 85px;
            line-height: 5.2vw;
            text-align: right;
            /* or 118% */
            color: #7070F9;
            @media (max-width: 1920px) {
                font-size: 4.42vw;
                right: 4.5vw;
            }
            @media (max-width: 500px) {
                font-size: 44px;
                line-height: 55px;
                max-width: 330px;
                top: 83px;
                left: auto;
                right: 20px;
                text-align: right;
            }
        }
        .feature3Description {
            position: absolute;
            top: 27.5vw;
            left: 65.5vw;
            max-width: 542px;
            height: 246px;
            font-family: 'HanSans Medium', Futura, serif;
            
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            color: #E7E9EF;
            margin-right: 20px;
            @media (max-width: 500px){
                padding: 0px 22px;
                margin-right: 0px;
                left: 0px;
                top: 492px;
                max-width: 100vw;
                height: auto;
            }
        }
    }
    .free {
        margin-top: 200px;
        background-color: black;
        width: 100vw;
        height: 9.89vw;
        background-image: url('../../images/pc/drumnet4.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 100%;
        @media  (max-width: 500px){
            background-image: url('../../images/mb/drumnet4.png');
            width: 263.5px;
            height: 95px;
            margin-top: 370px;
        }
    }
    .downloads {
        margin-top: 200px;
        margin-bottom: 270px;
        display: flex;
        justify-content: center;
        @media  (max-width: 500px){
            flex-direction: column;
            justify-content: space-between;
            margin-top: 200px;
            margin-bottom: 140px;
            height: 230px;
        }
        .download {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-family: 'HanSans Medium', Futura, serif;
            
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: #E7E9EF;
            margin: 0px 46px;
    
        }
    }
    .button {
        width: 341px;
        height: 56px;
        background: #7070F9;
        border-radius: 28px;
        text-transform: none;
        font-family: 'HanSans Medium', Futura, serif;
        
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        margin-bottom: 12px;
    }
    .button:hover {
        background-color: #4646E0;
    }
    .text {
        font-family: 'HanSans Medium', Futura, serif;
        
        font-weight: 500;
        font-size: 23px;
        line-height: 31px;
        text-align: center;
        color: #FFFFFF;
        @media  (max-width: 500px){
            max-width: 330px;
            font-size: 18px;
            line-height: 32px;
            text-align: center;
        }
    }
    //.buys {
    //    display: flex;
    //    justify-content: center;
    //    margin-top: 90px;
    //    position: relative;
    //    width: auto;
    //    @media (max-width: 500px){
    //        height: 720px;
    //        flex-direction: column;
    //        align-items: center;
    //        justify-content: space-between;
    //    }
    //    .buy{
    //        width: 318px;
    //        display: flex;
    //        flex-direction: column;
    //        align-items: center;
    //        .buyTitle {
    //            width: 100%;
    //            height: 77.5px;
    //            font-family: 'HanSans Medium', Futura, serif;
    //
    //            font-weight: 500;
    //            font-size: 20px;
    //            line-height: 27px;
    //            text-align: center;
    //            color: #E7E9EF;
    //        }
    //        .buyPrice {
    //            width: 100%;
    //            height : 83px;
    //            position: relative;
    //            display: flex;
    //            align-items: center;
    //            justify-content: center;
    //            font-weight: 500;
    //            font-size: 48px;
    //            line-height: 64px;
    //            text-align: center;
    //            color: #7070F9;
    //            @media (max-width: 500px){
    //                border-top: 0.5px solid white;
    //                border-bottom: 0.5px solid white;
    //            }
    //            .priceDiscount {
    //                position: absolute;
    //                left: 17px;
    //                top: 0px;
    //                width: 110px;
    //                height: 66px;
    //                font-family: 'HanSans Medium', Futura, serif;
    //
    //                font-weight: 500;
    //                font-size: 32px;
    //                line-height: 64px;
    //                text-align: center;
    //                text-decoration-line: line-through;
    //                color: #7070F9;
    //            }
    //            .priceSegment {
    //                position: absolute;
    //                width: 0.5px;
    //                height: 50px;
    //                top: 15px;
    //                left: 120px;
    //                background: white;
    //            }
    //            .priceNow {
    //                position: absolute;
    //                left: 123px;
    //                top: 6px;
    //                width: 110px;
    //                height: 66px;
    //                font-family: 'HanSans Medium', Futura, serif;
    //
    //                font-weight: 500;
    //                font-size: 48px;
    //                line-height: 64px;
    //                text-align: center;
    //                color: #7070F9;
    //            }
    //            .priceTag {
    //                position: absolute;
    //                left: 232px;
    //                top: 15px;
    //                width: 87px;
    //                height: 66px;
    //                font-family: 'HanSans Medium', Futura, serif;
    //                text-align: left;
    //                font-weight: 500;
    //                font-size: 14px;
    //                line-height: 14px;
    //                color: #E7E9EF;
    //            }
    //            .priceSubscription {
    //                font-family: 'HanSans Medium', Futura, serif;
    //
    //                font-weight: 500;
    //                font-size: 24px;
    //                color: #E7E9EF;
    //            }
    //        }
    //        .buyDescription {
    //            padding-top: 13px;
    //            padding-bottom: 54px;
    //            font-family: 'HanSans Medium', Futura, serif;
    //
    //            font-weight: 500;
    //            font-size: 14px;
    //            line-height: 19px;
    //            text-align: center;
    //            color: #E7E9EF;
    //        }
    //    }
    //    .buySegment1 {
    //        position: absolute;
    //        width: 100%;
    //        height: 0.5px;
    //        top: 77.5px;
    //        left: 0px;
    //        background: white;
    //        @media (max-width: 500px){
    //            display: none;
    //        }
    //
    //    }
    //    .buySegment2 {
    //        position: absolute;
    //        width: 100%;
    //        height: 0.5px;
    //        top: 160.5px;
    //        left: 0px;
    //        background: white;
    //        @media (max-width: 500px){
    //            display: none;
    //        }
    //    }
    //}

    .downloadTips {
        text-align: center;
        margin-top: 400px;
        margin-bottom: 50px;
        .downloadTipsTitle {
            color: #E7E9EF;
            font-size: 58px;
            font-family: Futura, serif;
        }
        .downloadTipsAction {
            margin-top: 20px;
            color: #7070F9;
            font-size: 32px;
            font-family: 'HanSans Medium', Futura, serif;
            font-weight: 700;
        }
    }

    .exchange {
        margin-top: 100px;
        display: flex;
        flex-direction: column;
    }

    .field {
        border-radius: 5px;
        border: 1px #7F7F8E solid;
        width: 339px;
        height: 40px;
        & + .field {
            margin-top: 16px;
        }
        input {
            color: #7F7F8E;
        }
        :global(.Mui-focused) {
            input {
                color: #E7E9EF;
            }
            fieldset {
                border: 2px #7070F9 solid;
            }
        }
    }

    .tipsMsg {
        height: 44px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.tipsMsgSucc {
            color: #0ED067;
        }
        &.tipsMsgErr {
            color: #D01A0E;
        }
    }

    .submit {
        font-size: 18px;
        background: #7070F9;
        border-radius: 54px;
        height: 54px;

        &:hover {
            background: #7070F9;
        }
        &:global(.Mui-disabled) {
            background-color: rgba(112, 112, 249, 0.8);
        }
    }
    .copyright {
        color: #E7E9EF;
        padding: 60px 30px 30px 30px;
    }
}


.closeIcon {
    position: absolute;
    right: -25px;
    top: -25px;
    color: #FFF;
    z-index: 99999999999999;
    cursor: pointer;
}
.qrPaper {
    overflow: visible !important;
}