
@font-face {
  font-family: "Futura Condensed ExtraBold";
  src: local('Futura-CondensedExtraBold'), url("fonts/FuturaCondensed-ExtraBold.woff");
}

@font-face {
  font-family: "Futura Bold";
  src: local('Futura-Bold'), url("fonts/Futura-Bold.woff");
}

@font-face {
  font-family: "Futura Medium";
  src: local('Futura-Medium'), url("fonts/Futura-Medium.woff");
}

@font-face {
  font-family: "Futura Condensed Medium";
  src: url("fonts/FuturaCondensed-Medium.woff");
}

@font-face {
  font-family: question;
  src: local('Roboto'), local('Helvetica'), local('Arial');
  unicode-range: U+003F;
}

/* @font-face {
  font-family: 'Noto Serif SC';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Serif SC'), local('NotoSerifSC-Regular'), url(./fonts/H4chBXePl9DZ0Xe7gG9cyOj7oqOcaThrVMcaeccjhXXDsOyAEEmuIi6j7j64sLjgBtMI1z49XW4.4.woff2) format('woff2');
} */

html, body {
  background: #F2F2F2;
  margin: 0;
  padding: 0;
  /* width: 100%;
  height: 100%; */
  /* overflow-x: hidden; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.defaultFont {
  font-family: question, "Futura Medium", "Futura Condensed Medium", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
#root {
  font-family: question, "Futura Medium", "Futura Condensed Medium", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
sup {
  top: -0.5em;
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
