
@import '../../shared.scss';

.root {
  padding: 0;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  min-height: 100vh;

  .logo {
    height: 100%;
    width: 100px;
    background-image: url('../../logo.svg');
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 82px;
    cursor: pointer;
    @media (max-width: 1189px) {
      margin-right: 60px;
    }
  }

  .cell {
    margin-right: 60px;

    @media (max-width: $layout-breakpoint-small) {
      margin-right: 30px;
    }
  }
  
  .link {
    @extend .cell;

    font-size: 18px;
    font-family: 'Futura Medium', sans-serif;
    font-weight: 500;
    color: rgba(161, 161, 161, 1);
    line-height: 24px;
    white-space: nowrap;

    &:hover {
      text-decoration: none;
      color: rgba(255, 255, 255, 1);
    }
    &.active {
      color: rgba(255, 255, 255, 1);
    }
  }

  .help {
    height: 60px;
    line-height: 60px;
    cursor: pointer;
    display: block;
  }
  
  .header {
    min-height: 60px;
    background-color: rgba(34, 34, 34, 1);
    box-shadow: none;
    position: sticky;
    z-index: 200;
  }

  .toolbar {
    display: flex;
    min-height: 60px;
    background-color: inherit;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
    @media (max-width: $layout-breakpoint-min) {
      display: none;
    }

    .container {
      padding: 0 150px;
      width: 100%;
      display: flex;
      flex-direction: row;
      min-height: 60px;

      @media (max-width: 1189px) {
        padding: 0 30px;
      }

      @media (max-width: 1441px) {
        padding: 0 30px 0 80px;
      }

      @media (max-width: 860px) {
        padding: 0 30px 0 30px;
      }

      .left {
        flex: 1;
        min-height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .right {
        @extend .left;
        justify-content: flex-end;

        .first {
          margin-left: 0;
        }
      }
    }
  }

  .credit {
    // min-width: 75px;
    padding: 0 8px 0 2px;
    height: 22px;
    background: #464646;
    border-radius: 13px;
    border: 2px solid #9c9696;
    // line-height: 26px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
    @media (max-width: 500px) {
      margin-right: 0;
    }

    // font-size: 16px;
    // font-family: 'Futura Medium', Futura;
    // font-weight: 500;

    .creditIcon {
      width: 30px;
      height: 26px;
    }

    .creditIcon > svg {
      color: #9c9696;
      height: 20px;
      margin: 3px 8px 0 3px;
    }

    .creditValue {
      font-size: 16px;
      height: 26px;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-family: Futura, sans-serif;
    }
  }

  .trial {
    @extend .cell;
    // width: 126px;
    height: 30px;
    background: linear-gradient(180deg, #FFAF5A 0%, #FF651A 100%);
    border-radius: 15px;
    font-family: 'Futura Medium', Futura;
    font-size: 16px;
    // line-height: 30px;
    text-align: center;
    text-transform: none;
    white-space: nowrap;
    @media (max-width: 500px) {
      margin-right: 0;
    }
  }

  .avatar {
    @extend .cell;
    height: 26px;
    width: 26px;
    cursor: pointer;
    border: 2px solid #FFF;
    margin-right: 0;
    @media (max-width: 500px) {
      display: none;
    }
  }

  .toolbar2 {
    display: flex;
    height: 60px;
    min-height: 60px;
    background-color: inherit;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
    @media (min-width: $layout-breakpoint-min + 1) {
      display: none;
    }

    .left {
      flex: 1;
      height: 60px;
      min-height: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .right {
      @extend .left;
      justify-content: flex-end;
    }

    .logo {
      margin-left: 15px;
      @media (max-width: 450px) {
        margin-right: 15px;
      }
    }

  }

  .line {
    min-height: 1px;
    margin-top: -1px;
    z-index: 2;
    background: rgba(255, 101, 26, 1);
  }

  .body {
    display: block;
  }
  
  .footerLine {
    display: none;
    // margin: 260px auto 0 auto;
    padding-bottom: 111px;
    // width: 1300px;
    // height: 1px;
    // border-top: 1px solid #999999;
  }

  .login {
    white-space: nowrap;
  }
  .signup {
    white-space: nowrap;
  }
}

.helpRoot {
  .helpPaper {
    background-color: #222222;
    color: #eee;
    margin-left: -50px;

    ul li:hover {
      background-color: #000;
      color: #FFF;

    }

    ul li {
      svg {
        margin-right: 10px;
      }
    }
  }
}

:global(.not-found):global(.anonymous) {
  margin-top: 100px;
}